import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createAuthorization, deleteAuthorization, getAuthorizations, updateAuthorization } from '@/modules/project/model';
import { KisAuthorization, RightsEnum } from '@/modules/project/types/project.types';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faPenLine } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Modal, Popconfirm, Space, Table, notification } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';
import { AuthorizationModal } from './components/authorizationModal';
interface AuthorizationProps {
  modal: { title: string; content: ReactNode; visible: boolean };
  setModal: Dispatch<SetStateAction<{ title: string; content: ReactNode; visible: boolean }>>;
}
export const Authorization: FC<AuthorizationProps> = ({ modal, setModal }) => {
  const { t } = useTranslation();
  const [authorizations, setAuthorizations] = useState<KisAuthorization[]>();
  const [form] = Form.useForm<KisAuthorization>();
  const abortController = new AbortController();

  const { projectId } = useParams();

  const fetchData = useCallback(() => {
    if (!projectId) return;
    getAuthorizations(projectId, abortController.signal)
      .then(data => {
        setAuthorizations(data);
      })
      .catch(error => {
        if (!abortController.signal.aborted) notification.error({ message: error.request.response || t('somethingWentWrong') });
      });
  }, [abortController.signal, projectId, t]);

  useEffect(() => {
    fetchData();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalSave = values => {
    if (!projectId) return;
    if (values.type === 'add')
      createAuthorization(projectId, values, abortController.signal)
        .then(() => {
          notification.success({ message: t('projects.authorization.added') });
          fetchData();
        })
        .catch(error => {
          if (!abortController.signal.aborted)
            notification.error({ message: error.request.response || t('projects.authorization.notAdded') });
        });
    else if (values.type === 'edit')
      updateAuthorization(projectId, values, abortController.signal)
        .then(() => {
          notification.success({ message: t('projects.authorization.updated') });
          fetchData();
        })
        .catch(error => {
          if (!abortController.signal.aborted)
            notification.error({ message: error.request.response || t('projects.authorization.notUpdated') });
        });
    setModal({ title: '', content: null, visible: false });
    form.resetFields();
  };

  const columns: ColumnsType<KisAuthorization> = [
    {
      title: t('projects.company'),
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: t('projects.generalInformation.title'),
      dataIndex: 'general',
      key: 'general',
      children: [
        {
          title: t('projects.generalInformation.project.title'),
          dataIndex: 'general',
          key: 'general',
          render: (general: number) => {
            switch (general) {
              case RightsEnum.OWNER:
                return t('projects.authorization.owner');
              case RightsEnum.WRITE:
                return t('projects.authorization.write');
              case RightsEnum.READ:
                return t('projects.authorization.read');
              default:
                return t('projects.authorization.none');
            }
          },
        },
        {
          title: t('projects.authorization.hubExport'),
          dataIndex: 'generalHubExport',
          key: 'generalHubExport',
          render: (generalHubExport: number) => {
            return generalHubExport ? t('actions.yes') : t('actions.no');
          },
        },
      ],
    },
    {
      title: t('projects.geometryAndConstruction.title'),
      dataIndex: 'geometryAndConstruction',
      key: 'geometryAndConstruction',
      children: [
        {
          title: t('projects.generalInformation.project.title'),
          dataIndex: 'geometryAndConstruction',
          key: 'geometryAndConstruction',
          render: (geometryAndConstruction: number) => {
            switch (geometryAndConstruction) {
              case RightsEnum.OWNER:
                return t('projects.authorization.owner');
              case RightsEnum.WRITE:
                return t('projects.authorization.write');
              case RightsEnum.READ:
                return t('projects.authorization.read');
              default:
                return t('projects.authorization.none');
            }
          },
        },
        {
          title: t('projects.authorization.hubExport'),
          dataIndex: 'geometryAndConstructionHubExport',
          key: 'geometryAndConstructionHubExport',
          render: (geometryAndConstructionHubExport: number) => {
            return geometryAndConstructionHubExport ? t('actions.yes') : t('actions.no');
          },
        },
      ],
    },
    {
      title: t('projects.siom.title'),
      dataIndex: 'siom',
      key: 'siom',
      children: [
        {
          title: t('projects.generalInformation.project.title'),
          dataIndex: 'siom',
          key: 'siom',
          render: (siom: number) => {
            switch (siom) {
              case RightsEnum.OWNER:
                return t('projects.authorization.owner');
              case RightsEnum.WRITE:
                return t('projects.authorization.write');
              case RightsEnum.READ:
                return t('projects.authorization.read');
              default:
                return t('projects.authorization.none');
            }
          },
        },
        {
          title: t('projects.authorization.hubExport'),
          dataIndex: 'siomHubExport',
          key: 'siomHubExport',
          render: (siomHubExport: number) => {
            return siomHubExport ? t('actions.yes') : t('actions.no');
          },
        },
      ],
    },
    {
      title: t('projects.glazenStad.title'),
      dataIndex: 'glazenStad',
      key: 'glazenStad',
      children: [
        {
          title: t('projects.generalInformation.project.title'),
          dataIndex: 'glazenStad',
          key: 'glazenStad',
          render: (glazenStad: number) => {
            switch (glazenStad) {
              case RightsEnum.OWNER:
                return t('projects.authorization.owner');
              case RightsEnum.WRITE:
                return t('projects.authorization.write');
              case RightsEnum.READ:
                return t('projects.authorization.read');
              default:
                return t('projects.authorization.none');
            }
          },
        },
        {
          title: t('projects.authorization.hubExport'),
          dataIndex: 'glazenStadHubExport',
          key: 'glazenStadHubExport',
          render: (glazenStadHubExport: number) => {
            return glazenStadHubExport ? t('actions.yes') : t('actions.no');
          },
        },
      ],
    },
    {
      title: t('actions.title'),
      key: 'actions',
      align: 'right',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() =>
              setModal({
                title: `${t('projects.authorization.editAuthorization')}: ${record.companyName}`,
                visible: true,
                content: <AuthorizationModal type="edit" kisAuthorization={record} form={form} />,
              })
            }
            type="default">
            <FontAwesomeIcon icon={faPenLine} />
          </Button>
          <Popconfirm
            title={t('projects.authorization.deleteAuthorization')}
            description={t('projects.authorization.deleteAuthorizationDesc')}
            onConfirm={() => deleteAuthorization(record.id).then(() => fetchData())}
            placement="left"
            okText="Delete"
            cancelText="Cancel">
            <Button type="default">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={authorizations} pagination={false} bordered />

      <Modal
        title={modal.title}
        open={modal.visible}
        destroyOnClose
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          setModal(modal => {
            return { ...modal, visible: false };
          });
        }}>
        <Form onFinish={modalSave} form={form}>
          {modal.content}
        </Form>
      </Modal>
    </>
  );
};
